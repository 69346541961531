import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
// import VueSocketIO from "vue-socket.io";
import 'vuetify/dist/vuetify.min.css'
import vuetify from './plugins/vuetify'

Vue.use(Vuetify)

Vue.config.productionTip = false

import './router/permission'
import '@/assets/global.scss'

Vue.prototype.$isMobile = function() {
  return regexp.test(/android|iphone|kindle|ipad/i);
};

// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: `wss://${location.hostname}/sbkts/realchanges`,
//   vuex: {
//     store,
//     actionPrefix: 'SOCKET_',
//     mutationPrefix: 'SOCKET_'
//   },
// }))

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
