<script>
export default {
  watch: {
    value: {
      handler(value) {
        if (!value) this.close()
        else this.overlay = true
      },
      immediate: true
    }
  },
  data() {
    return {
      src: null,
      meta: null,
      actions: null,
      rotations: 0,
      overlay: false
    }
  },
  computed: {
    imageStyle() {
      const el = document.querySelector('#fullscreen-image')
      let scale = 0.8
      if (el) {
        const rect = el.getBoundingClientRect()
        if (this.rotations % 2 !== 0) {
          if (
            window.innerWidth < window.innerHeight &&
            rect.width < rect.height
          ) {
            scale = rect.width / rect.height
          } else if (
            window.innerHeight < rect.width &&
            rect.height < rect.width
          ) {
            scale = rect.height / rect.width
          }
        }
      }
      return {
        transform: `rotate(${this.rotations * 90}deg) scale(${Math.min(
          scale,
          0.8
        )})`
      }
    }
  },
  methods: {
    async open(value) {
      this.overlay = true
      if (value.then) {
        const { src, meta, actions } = await value
        if (!this.overlay) return
        if (actions) this.actions = actions
        this.src = src
        this.meta = meta
      } else {
        this.src = value.src
        this.meta = value.meta
      }
    },
    close() {
      this.src = null
      this.meta = null
      this.actions = null
      this.rotations = 0
      this.overlay = false
    },
    handleActionClick(handler) {
      if (typeof handler === 'function') handler(this)
    }
  },
  beforeMount() {
    this.$root.$on('open-fullscreen-image', this.open)
  },
  beforeDestroy() {
    this.$root.$off('open-fullscreen-image', this.open)
  }
}
</script>

<template>
  <v-overlay
    :value="overlay"
    opacity="0.8"
    class="fullscreen-image align-center justify-center"
  >
    <v-btn
      @click="close"
      icon
      width="80"
      height="80"
      tile
      class="fullscreen-image__close"
    >
      <v-icon size="36">mdi-close</v-icon>
    </v-btn>
    <div class="fullscreen-image__meta">
      <div v-for="(item, i) in meta" :key="i">
        <div class="fullscreen-image__meta--name">{{ item.name }}:</div>
        <div>{{ item.value }}</div>
      </div>
    </div>
    <div class="fullscreen-image__container">
      <v-img
        :src="src"
        id="fullscreen-image"
        aspect-ratio="1.7"
        contain
        class="fullscreen-image__image"
        :style="imageStyle"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <div class="fullscreen-image__actions" v-if="actions">
      <v-btn
        v-for="(item, i) in actions"
        :key="i"
        icon
        width="80"
        height="80"
        tile
        @click="handleActionClick(item.handler)"
        :loading="item.loading"
        :disabled="item.loading"
      >
        <v-icon size="36">{{ item.icon }}</v-icon>
      </v-btn>
    </div>
  </v-overlay>
</template>

<style lang="scss" scoped>
.fullscreen-image {
  z-index: 999 !important;

  &__container {
    width: 100vw;
  }

  &__close {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
  }

  &__image {
    transform: scale(0.75);
    pointer-events: none;
    transition: transform 0.3s ease;
  }

  &__actions {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }

  &__meta {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    left: 1rem;
    bottom: 1rem;
    color: #f7f7f7;
    z-index: 10;

    &--name {
      margin-right: 0.25rem;
      opacity: 0.5;
    }
  }
}
</style>
