<template>
  <v-navigation-drawer
    dark
    fixed
    app
    :value="navbarShow"
    v-model="show"
    ref="drawer"
    :width="navigation.width"
  >
    <v-app-bar v-if="navbarLogo" :dense="toolbarDense" dark>
      <v-toolbar-title class="text-center">
        <v-avatar size="32px" tile>
          <img src="img/icons/android-icon-36x36.png" alt="VVA" />
        </v-avatar>
        <span>{{ 'toolbar.appname' }}</span>
      </v-toolbar-title>
    </v-app-bar>
    <the-layout-drawer-list
      :dense="navbarDense"
      :routes="permissionRoutes"
      icon-show
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import TheLayoutDrawerList from './TheLayoutDrawerList.vue'

export default {
  name: 'TheLayoutDrawer',
  components: {
    TheLayoutDrawerList
  },
  data() {
    return {
      show: this.$store.state.settings.navbar.show,
      navigation: {
        width: 300,
        borderSize: 3
      }
    }
  },
  computed: {
    ...mapGetters([
      'permissionRoutes',
      'navbarDense',
      'navbarShow',
      'navbarLogo',
      'toolbarDense'
    ])
  },
  watch: {
    show(value) {
      this.$store.dispatch('NavbarState', { state: value })
    }
  },
  methods: {
    setBorderWidth() {
      const i = this.$refs.drawer.$el.querySelector(
        '.v-navigation-drawer__border'
      )
      i.style.width = this.navigation.borderSize + 'px'
      i.style.cursor = 'ew-resize'
      i.style.backgroundColor = '#2196f3'
    },
    setEvents() {
      const minSize = this.navigation.borderSize
      const el = this.$refs.drawer.$el
      const drawerBorder = el.querySelector('.v-navigation-drawer__border')
      const direction = el.classList.contains('v-navigation-drawer--right')
        ? 'right'
        : 'left'

      function resize(e) {
        document.body.style.cursor = 'ew-resize'
        const f =
          direction === 'right'
            ? document.body.scrollWidth - e.clientX
            : e.clientX
        el.style.width = f + 'px'
      }

      drawerBorder.addEventListener(
        'mousedown',
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = 'initial'
            document.addEventListener('mousemove', resize, false)
          }
        },
        false
      )

      document.addEventListener(
        'mouseup',
        () => {
          el.style.transition = ''
          this.navigation.width = el.style.width
          document.body.style.cursor = ''
          document.removeEventListener('mousemove', resize, false)
        },
        false
      )
    }
  },
  created() {
    this.$root.$on('show-navbar', (value) => {
      this.show = value
    })
  },
  mounted() {
    this.setBorderWidth()
    this.setEvents()
  },
  beforeDestroy() {
    this.$root.$off('show-navbar')
  }
}
</script>
