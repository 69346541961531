import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Layout from '../views/layout/TheLayout.vue'
import authRouter from './modules/auth'
import documentRouter from './modules/document'
// import permissionRouter from "./modules/permission";
// import directoryRouter from "./modules/directory";
// import claimsRouter from "./modules/claims";
// import blankRouter from './modules/blank'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/landing',
    alias: '/land',
    component: () => import('../views/auth/Landing.vue'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/dashboard',
        alias: '/',
        component: () => Vue.component('Country', import('../views/components/Directory/ListTable')),
        props: {
          model: 'Claim',
          dataRequest: 'Claim',
          component: 'ListTableClaim'
        },
        meta: { title: 'Главная страница', roles: ['admin'], icon: 'mdi-view-dashboard', noCache: true, affix: true }
      }
    ]
  },
  ...authRouter
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRoutes
})

export default router

export const asyncRoutes = [
  // /** When your routing table is too long, you can split it into small modules */
  // directoryRouter,
  // claimsRouter,
  // blankRouter,
  // permissionRouter,
  // vuetifyRouter,
  // ...componentsRouter,
  // nestedRouter,
  // errorsRouter,
  documentRouter,
  { path: '*', redirect: '/error/404', hidden: true }
]
