<template>
  <v-main>
    <v-snackbar color="deep-purple accent-4" :top="true" :right="true" v-model="snackbar" :timeout="2000">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Закрыть </v-btn>
      </template>
    </v-snackbar>
    <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition>
  </v-main>
</template>

<script>
export default {
  name: 'TheLayoutContent',
  data: () => ({
    snackbarText: '',
    snackbar: false
  }),
  methods: {
    showSnackbar(text) {
      this.snackbarText = text
      this.snackbar = true
    }
  },
  created() {
    this.$root.$on('snackbar', this.showSnackbar)
  },
  beforeDestroyed() {
    this.$root.$off('snackbar', this.showSnackbar)
  }
}
</script>
