import Vue from 'vue'
import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify/lib'
import themes from './vuetifyThemes'

Vue.use(Vuetify)

const opts = {}

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: themes[0]
  },
  icons: {
    iconfont: 'mdi'
  },
  lang: {
    locales: { ru: { ...require('vuetify/lib/locale/ru').default } },
    current: 'ru'
  }
})

export async function setVuetifyTheme(index) {
  if (vuetify.framework.theme.themes !== themes[index]) {
    console.log(`[Vuetify] Change theme to "${index}"`)
    vuetify.framework.theme.themes.light = themes[index].light || themes[0].light
    vuetify.framework.theme.themes.dark = themes[index].dark || themes[0].dark
  } else {
    console.warn(`[Vuetify] "${index}" is current theme `)
  }
}

/**
 * Set the mode to dark/light for the Vuetify class object.
 *
 * @param {Boolean} dark new value
 */
export async function setVuetifyThemeDark(dark) {
  console.log(`[Vuetify] Change theme to "${dark ? 'dark' : 'light'}"`)
  vuetify.framework.theme.dark = dark
}

export default new Vuetify(opts)
