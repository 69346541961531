/** When your routing table is too long, you can split it into small modules */

import Layout from '../../views/layout/TheLayout.vue'

const documentRouter = {
  path: '/document',
  component: Layout,
  redirect: 'noredirect',
  name: 'Permission',
  meta: {
    title: 'Документ',
    icon: 'mdi-calendar-check'
  },
  hidden: true,
  children: [
    {
      path: 'records/:id',
      component: () => import('../../views/components/Document/DocumentRecords'),
      meta: { title: 'Заявки', roles: ['admin'], noCache: true }
    }
  ]
}

export default documentRouter
