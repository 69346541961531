<template>
  <v-app>
    <fullscreen-image />
    <router-view />
  </v-app>
</template>

<script>
import FullscreenImage from '@/components/FullscreenImage'

export default {
  components: {
    FullscreenImage
  }
}
</script>
